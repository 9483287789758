<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-rating modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>รีวิวความพึงพอใจ</span>
                        </h4>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <template v-if="step === 1">
                            <div class="product">
                                <img :src="content.products[0].brandLogo" :alt="content.products[0].fullName" :title="content.products[0].fullName" @error="helper.setNoImg($event, 'product')" />

                                <div class="info">
                                    <p v-for="(item, index) in content.products" :key="index">{{ item.fullName }} (ปี {{ item.year }})</p>
                                    <span>หมายเลขคำสั่งซื้อ {{ content.orderNumber }}</span>
                                </div>
                            </div>

                            <div class="store">
                                <img :src="content.storeData.picture" :alt="content.storeData.name" :title="content.storeData.name" @error="helper.setNoImg($event, 'store')" />

                                <p>{{ content.storeData.name }}</p>
                                <span v-if="content.orderType === constants.ORDER_TYPE.SERVICE">{{ serviceDate }}</span>
                                <span v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">{{ serviceDate }}</span>
                            </div>

                            <div class="review" v-if="formReview">
                                <div class="input">
                                    <label>บริการของร้านยาง</label>
                                    <div class="stars">
                                        <b-icon icon="star-fill"
                                            @click="addRating(index + 1, 'Store')"
                                            class="icon"
                                            :class="{ 'active': (index + 1) <= formReview.ratingStore, 'no-click': content.myRating.isCompleteReview }"
                                            v-for="(star, index) in 5"
                                            :key="index">
                                        </b-icon>
                                    </div>
                                </div>
                                <div class="input">
                                    <label>คุณภาพสินค้า</label>
                                    <div class="stars">
                                        <b-icon icon="star-fill"
                                            @click="addRating(index + 1, 'Product')"
                                            class="icon"
                                            :class="{ 'active': (index + 1) <= formReview.ratingProduct, 'no-click': content.myRating.isCompleteReview }"
                                            v-for="(star, index) in 5"
                                            :key="index">
                                        </b-icon>
                                    </div>
                                </div>
                                <div class="input">
                                    <label>การใช้งานเยลโลไทร์</label>
                                    <div class="stars">
                                        <b-icon icon="star-fill"
                                            @click="addRating(index + 1, 'YellowTire')"
                                            class="icon"
                                            :class="{ 'active': (index + 1) <= formReview.ratingYellowTire, 'no-click': content.myRating.isCompleteReview }"
                                            v-for="(star, index) in 5"
                                            :key="index">
                                        </b-icon>
                                    </div>
                                </div>
                                <div class="input comment">
                                    <label>เขียนรีวิวเพิ่มเติม</label>
                                    <div class="field">
                                        <textarea rows="3" v-model="formReview.comment" :readonly="content.myRating.isCompleteReview"></textarea>
                                    </div>
                                </div>

                                <div class="action mt-4" v-if="!content.myRating.isCompleteReview">
                                    <button class="btn btn-main" @click="saveReview()">ยืนยัน</button>
                                    <button class="btn btn-reset btn-outline-secondary" @click="hide()">ยกเลิก</button>
                                </div>
                            </div>
                        </template>
                        <template v-if="step === 2">
                            <div class="thank-you">
                                <b-icon icon="check2-circle" class="icon"></b-icon>

                                <span class="title">ขอขอบคุณสำหรับ การรีวิวของท่าน</span>


                                <div class="reward" v-if="reward && !content.myRating.isSubmitRewardAddress">
                                    <img :src="reward.banner" />
                                    <span>{{ reward.message }}</span>
                                    
                                    <label><b-icon icon="geo-alt"></b-icon> กรอกข้อมูล สำหรับจัดส่งบัตรของขวัญ</label>
                                    <div class="form" v-if="formAddress">
                                        <div class="row">
                                            <div class="col col-6 col-lg-4">
                                                <div class="input-field">
                                                    <input type="text"
                                                        v-model="formAddress.firstName"
                                                        maxlength="100"
                                                        placeholder="ชื่อผู้รับ"
                                                        :class="{ 'invalid': errorAddress.firstName }" />
                                                </div>
                                            </div>
                                            <div class="col col-6 col-lg-4">
                                                <div class="input-field">
                                                    <input type="text"
                                                        v-model="formAddress.lastName"
                                                        maxlength="100"
                                                        placeholder="นามสกุลผู้รับ"
                                                        :class="{ 'invalid': errorAddress.lastName }" />
                                                </div>
                                            </div>
                                            <div class="col col-6 col-lg-4">
                                                <div class="input-field">
                                                    <input type="tel"
                                                        @keypress="$AppUtility.inputOnlyNumber($event)"
                                                        v-model="formAddress.tel"
                                                        maxlength="20"
                                                        placeholder="เบอร์ติดต่อผู้รับ"
                                                        :class="{ 'invalid': errorAddress.tel }" />
                                                </div>
                                            </div>
                                            <div class="col col-12 col-sm-6 col-lg-4">
                                                <div class="input-field">
                                                    <input type="text"
                                                        v-model="formAddress.email"
                                                        maxlength="100"
                                                        placeholder="อีเมลผู้รับ"
                                                        :class="{ 'invalid': errorAddress.email }" />
                                                </div>
                                            </div>
                                            <div class="col col-12 col-sm-6 col-lg-8">
                                                <div class="input-field">
                                                    <input type="text"
                                                        v-model="formAddress.address"
                                                        maxlength="100"
                                                        placeholder="บ้านเลขที่/หมู่บ้าน/ซอย/ถนน"
                                                        :class="{ 'invalid': errorAddress.address }" />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="row">
                                            <div class="col col-6">
                                                <div class="input-field">
                                                    <address-auto-complete
                                                        ref="thaiAddressDataTumbon"
                                                        placeholder="ตำบล/แขวง"
                                                        :listData="mixinsThaiAddressList"
                                                        v-model="formAddress.subDistrict"
                                                        :inputClass="[(errorAddress.subDistrict ? 'invalid' : '')]"
                                                        @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                                        @input="mixinsThaiAddressSetAutoComplete($event, 'tumbon')"
                                                        @blur="mixinsThaiAddressCheckValidInputAddress($event, 'tumbon')" />
                                                </div>
                                            </div>
                                            <div class="col col-6">
                                                <div class="input-field">
                                                    <address-auto-complete
                                                        ref="thaiAddressDataAmphur"
                                                        placeholder="อำเภอ/เขต"
                                                        :listData="mixinsThaiAddressList"
                                                        v-model="formAddress.district"
                                                        :inputClass="[(errorAddress.district ? 'invalid' : '')]"
                                                        @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                                        @input="mixinsThaiAddressSetAutoComplete($event, 'amphur')"
                                                        @blur="mixinsThaiAddressCheckValidInputAddress($event, 'amphur')" />
                                                </div>
                                            </div>
                                            <div class="col col-6">
                                                <div class="input-field">
                                                    <address-auto-complete
                                                        ref="thaiAddressDataProvince"
                                                        placeholder="จังหวัด"
                                                        :listData="mixinsThaiAddressList"
                                                        v-model="formAddress.province"
                                                        :inputClass="[(errorAddress.province ? 'invalid' : '')]"
                                                        @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                                        @input="mixinsThaiAddressSetAutoComplete($event, 'province')"
                                                        @blur="mixinsThaiAddressCheckValidInputAddress($event, 'province')" />
                                                </div>
                                            </div>
                                            <div class="col col-6">
                                                <div class="input-field">
                                                    <address-auto-complete
                                                        ref="thaiAddressDataPostCode"
                                                        placeholder="รหัสไปรษณีย์"
                                                        :listData="mixinsThaiAddressList"
                                                        v-model="formAddress.postCode"
                                                        :inputClass="[(errorAddress.postCode ? 'invalid' : '')]"
                                                        @hit="mixinsThaiAddressSetThaiAddressValue($event)"
                                                        @input="mixinsThaiAddressSetAutoComplete($event, 'postcode')"
                                                        @blur="mixinsThaiAddressCheckValidInputAddress($event, 'postcode')" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col col-12">
                                                <div class="input-field">
                                                    <input type="text"
                                                        v-model="formAddress.deliveryRemark"
                                                        maxlength="200"
                                                        placeholder="หมายเหตุการจัดส่ง" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-center mt-3">
                                        <button class="btn btn-main" @click="submitDeliveryInfo()">ยืนยัน</button>
                                    </div>
                                </div>

                                <div class="text-center mt-3" v-else>
                                    <button class="btn btn-main" @click="hide()">ปิด</button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import ThaiAddress from '@/mixins/thaiAddress';
import Constants from '@/variables/constants';
import UserService from '@/services/userService';

export default {
    mixins: [ ThaiAddress ],
    data() {
		return {
            helper: Helper,
            constants: Constants,
            isActive: false,
            formReview: null,
            formAddress: null,
            errorAddress: this.initErrorAddress(),
            step: 0
		};
	},
	props: {
		isDisplay: Boolean,
        reward: Object,
        content: Object
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);

            if (value) {
                this.$root.$emit('rootToggleFacebookChat', false);

                this.initPage();
                this.formReview = this.initFormReview();
                this.formAddress = this.initFormAddress();
            } else {
                this.$root.$emit('rootToggleFacebookChat', true);
            }
        }
    },
    destroyed() {
        this.$root.$emit('rootToggleFacebookChat', true);
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide() {
            this.$emit('close');
        },
        initPage() {
            // Get service date
            switch (this.content.orderType) {
                case Constants.ORDER_TYPE.SERVICE:
                    this.serviceDate = 'เข้ารับบริการเมื่อ ' + this.content.appointmentDateText + ' - ' + this.content.appointmentTime;
                    break;

                case Constants.ORDER_TYPE.DELIVERY:
                    this.serviceDate = 'จัดส่งเมื่อวันที่ ' + this.content.deliveryData.deliveryDate + ' โดย ' + this.content.deliveryData.name
                    break;
            }

            // Get step
            this.step = 1;

            if (this.content.myRating.isCompleteReview && !this.content.myRating.isSubmitRewardAddress) {
                this.step = 2;
            }
        },
        initFormReview() {
            return {
                id: this.content.id,
                ratingStore: this.content.myRating.store ? this.content.myRating.store : 0,
                ratingProduct: this.content.myRating.product ? this.content.myRating.product : 0,
                ratingYellowTire: this.content.myRating.yellowTire ? this.content.myRating.yellowTire : 0,
                comment: this.content.myRating.comment ? this.content.myRating.comment : ''
            };
        },
        initFormAddress() {
            return {
                id: this.content.id,
                firstName: this.content.shippingData.receiverFirstname ? this.content.shippingData.receiverFirstname : '',
                lastName: this.content.shippingData.receiverLastname ? this.content.shippingData.receiverLastname : '',
                tel: this.content.shippingData.receiverTel ? this.content.shippingData.receiverTel : '',
                email: this.content.customer.email ? this.content.customer.email : '',
                address: this.content.customer.address ? this.content.customer.address : '',
                subDistrict: this.content.customer.subDistrict ? this.content.customer.subDistrict : '',
                district: this.content.customer.district ? this.content.customer.district : '',
                province: this.content.customer.province ? this.content.customer.province : '',
                postCode: this.content.customer.postCode ? this.content.customer.postCode : '',
                deliveryRemark: this.content.shippingData.deliveryRemark ? this.content.shippingData.deliveryRemark : '',
            };
        },
        initErrorAddress() {
            return {
                firstName: false,
                lastName: false,
                tel: false,
                email: false,
                address: false,
                subDistrict: false,
                district: false,
                province: false,
                postCode: false
            };
        },
        addRating(rate, property) {
            if (!this.content.myRating.isCompleteReview) {
                this.formReview['rating' + property] = rate;
            }
        },
        async saveReview() {
            if (this.validateReview()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.submitReview(this.formReview);
                loader.hide();

                if (result.data?.success) {
                    this.step = 2;
                }
            }
        },
        validateReview() {
            let isValid = true;
            
            if (this.formReview.ratingStore === 0
                && this.formReview.ratingProduct === 0
                && this.formReview.ratingYellowTire === 0
            ) {
                this.$swal(Helper.warningAlert('', 'คุณยังไม่ได้ให้คะแนนรีวิวความพึงพอใจ'));
                isValid = false;
            } else if (this.formReview.ratingStore === 0) {
                this.$swal(Helper.warningAlert('', 'คุณยังไม่ได้ให้คะแนนบริการร้านยาง'));
                isValid = false;
            } else if (this.formReview.ratingProduct === 0) {
                this.$swal(Helper.warningAlert('', 'คุณยังไม่ได้ให้คะแนนคุณภาพสินค้า'));
                isValid = false;
            } else if (this.formReview.ratingYellowTire === 0) {
                this.$swal(Helper.warningAlert('', 'คุณยังไม่ได้ให้คะแนนการใช้งานเยลโลไทร์'));
                isValid = false;
            }

            return isValid;
        },
        async submitDeliveryInfo() {
            if (this.validateAddress()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.submitRewardAddress(this.formAddress);
                loader.hide();

                if (result.data?.success) {
                    this.hide();
				}
            }
        },
        validateAddress() {
            this.errorAddress = this.initErrorAddress();
            let isValid = true;
            let isBlank = false;

            // Check Blank
            for (const property in this.errorAddress) {
                if (!this.formAddress[property]?.trim()) {
                    this.errorAddress[property] = true;
                    isValid = false;
                    isBlank = true;
                }
            }

            // Popup
            if (isBlank) {
                this.$swal(Helper.warningAlert('ยังไม่สามารถดำเนินการต่อได้', 'โปรดกรอกข้อมูลให้ครบถ้วน'));
            }

            return isValid;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-rating {
    max-width: 600px;

    .product {
        text-align: center;

        img {
            height: 30px;
            border: 1px solid #333;
        }

        .info {
            margin: 10px 0 0;

            p {
                font-size: 13px;
                margin: 0;
            }

            span {
                font-size: 11px;
                color: #999;
                display: block;
            }
        }
    }

    .store {
        margin-top: 15px;
        text-align: center;

        img {
            width: 120px;
        }

        p {
            font-size: 13px;
            font-weight: bold;
            line-height: 22px;
            margin: 10px 0 0;
        }

        span {
            display: block;
            font-size: 11px;
            line-height: 13px;
            color: #999;
        }
    }

    .review {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px dashed #ddd;

        .input {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 10px;

            &.comment {
                label {
                    @media only screen and (max-width: $screenExtraSmall) {
                        margin-top: 20px;
                        text-align: left;
                        width: 100%;
                    }
                }
            }
            
            label {
                margin: 0;
                width: 150px;
                font-size: 13px;
            }

            .stars {
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    color: #bbb;
                    margin: 2px 3px;
                    font-size: 26px;
                    cursor: pointer;

                    &.active {
                        color: #ffaa00;
                    }

                    &.no-click {
                        cursor: not-allowed;
                    }
                }
            }

            .field {
                flex: 1;
                margin-top: 10px;

                @media only screen and (max-width: $screenExtraSmall) {
                    width: 100%;
                }

                textarea {
                    display: block;
                    width: 100%;
                    border: 1px solid #ccc;
                    padding: 8px 16px;
                    font-size: 13px;
                }
            }
        }

        .action {
            padding-left: 150px;

            @media only screen and (max-width: $screenExtraSmall) {
                padding-left: 0;
                text-align: center;
            }
        }

        .btn {
            min-width: 150px;
            padding: 0 20px;
            height: 40px;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .thank-you {
        text-align: center;

        .icon {
            font-size: 80px;
            color: #3ab197;
        }

        .title {
            margin: 15px 0;
            color: #333;
            font-size: 18px;
            font-family: 'Open Sans', 'Kanit';
            display: block;
        }

        .reward {
            text-align: center;
            margin-top: 30px;

            &:before {
                content: "";
                display: block;
                margin: 20px auto;
                width: 100px;
                height: 2px;
                background: #3ab197;
            }

            img {
                display: block;
                width: 100%;
            }

            span {
                display: block;
                margin: 30px 20px 20px;
                font-size: 16px;
            }

            label {
                display: block;
                text-align: left;
                font-size: 13px;
                margin-bottom: 10px;
                color: #666;
            }

            .form {
                .row {
                    margin-left: -3px;
                    margin-right: -3px;

                    .col {
                        padding-left: 3px;
                        padding-right: 3px;
                    }
                }

                .input-field {
                    input {
                        font-size: 13px;
                        border: 1px solid #f2f2f2;
                        background: #f5f5f5;
                    }

                    ::v-deep .typeahead {
                        label {
                            font-size: 13px;
                        }

                        input {
                            font-size: 13px;
                            border: 1px solid #f2f2f2;
                            background: #f5f5f5;
                        }

                        .autcomplete-list {
                            max-height: 180px;

                            @media only screen and (max-width: $screenExtraSmall) {
                                max-height: 150px;
                            }

                            .autocomplete-item {
                                text-align: left;
                                padding: 5px 10px;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }

        .btn {
            min-width: 150px;
            padding: 0 20px;
            height: 40px;
            font-size: 14px;
            font-weight: bold;
        }
    }
}
</style>