<template>
    <div class="order">
        <div class="title">
            <div class="info">
                <div class="item">
                    <label>วันที่ทำรายการ</label>
                    <span>{{ content.orderDate }}</span>
                </div>
                <div class="item">
                    <label>สถานะ</label>
                    <span :class="getStatusColor()" v-if="content.orderType === constants.ORDER_TYPE.SERVICE">
                        {{ content.statusText }} : {{ content.appointmentDateText && content.appointmentTime ? content.appointmentDateText + ' - ' + content.appointmentTime : '' }}
                    </span>
                    <span :class="getStatusColor()" v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
                        {{ content.statusText }} : {{ content.deliveryData.name && content.deliveryData.ref ? content.deliveryData.name + ' - ' + content.deliveryData.ref : '' }}
                    </span>
                </div>
            </div>
        </div>
        <div class="main">
            <router-link 
                :to="helper.replaceRouteParam(routerPath.TIRE_DETAIL, ':id', content.products[0].param)"
                class="picture">
                <img :src="content.products[0].picture" :alt="content.products[0].fullName" :title="content.products[0].fullName" @error="helper.setNoImg($event, 'product')" />
            </router-link >
            <div class="right">
                <div class="info">
                    <div class="product" v-for="(item, index) in content.products" :key="index">
                        <h4>{{ item.fullName }} (ปี {{ item.year }})</h4>

                        <div class="price-data">
                            <div class="item">
                                <label>ราคาเส้นละ</label>
                                <span class="price">{{ displayPrice(item.price) }}</span>
                            </div>
                            <div class="item">
                                <label>จำนวน</label>
                                <span>{{ item.quantity }} เส้น</span>
                            </div>
                        </div>
                    </div>

                    <div class="price-data">
                        <div class="addon">
                            <div class="item" v-if="content.installmentFee && content.installmentFee !== '0'">
                                <label>ค่าธรรมเนียมการผ่อน</label>
                                <span class="price">{{ displayPrice(content.installmentFee) }}</span>
                            </div>
                            <div class="item" v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
                                <label>ค่าจัดส่ง {{ content.deliveryData.name }}</label>
                                <span class="price" v-if="content.deliveryData.price">{{ displayPrice(content.deliveryData.price) }}</span>
                                <span v-if="!content.deliveryData.price">ฟรี</span>
                            </div>
                            <div class="item" v-for="(service, index) in content.services" :key="'service' + index">
                                <label>{{ service.title }}</label>
                                <span class="price" v-if="service.price">{{ displayPrice(service.price) }}</span>
                                <span v-if="!service.price">ฟรี</span>
                            </div>
                            <div class="item" v-if="checkZeroPrice(content.discountPrice)">
                                <label>ส่วนลด</label>
                                <span class="price">- {{ displayPrice(content.discountPrice) }}</span>
                            </div>
                        </div>

                        <div class="addon" v-if="content.isPrepaid">
                            <div class="item">
                                <label>ยอดมัดจำ</label>
                                <span class="price">{{ displayPrice(content.prepaidPrice) }}</span>
                            </div>
                            <div class="item">
                                <label>ยอดที่เหลือหลังมัดจำ</label>
                                <span class="price">{{ displayPrice(content.remainPrice) }}</span>
                            </div>
                        </div>

                        <div class="total item">
                            <label>ยอดรวม</label>
                            <span class="price">{{ displayPrice(content.finalPrice) }}</span>
                        </div>
                    </div>
                    
                    <div class="item" v-if="content.orderType === constants.ORDER_TYPE.SERVICE">
                        <label>ใบนัดหมาย</label>
                        <router-link :to="helper.replaceRouteParam(routerPath.MY_RECEIPT, ':id', content.orderNumber)">เปิดดูใบนัดหมายติดตั้ง</router-link>
                    </div>
                    <div class="item" v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
                        <label>ใบสั่งซื้อสินค้า</label>
                        <router-link :to="helper.replaceRouteParam(routerPath.MY_RECEIPT, ':id', content.orderNumber)">กดเพื่อเปิดดูใบสั่งซื้อสินค้า</router-link>
                    </div>
                    <div class="item" v-if="content.installmentFee && content.installmentFee !== '0'">
                        <label>ใบเสร็จค่าธรรมเนียม</label>
                        <router-link :to="helper.replaceRouteParam(routerPath.MY_RECEIPT_FEE, ':id', content.orderNumber)">ดาวน์โหลดใบเสร็จค่าธรรมเนียม</router-link>
                    </div>
                    <div class="item">
                        <label>ร้านค้า</label>
                        <router-link :to="routerPath.STORE + '/' + content.storeData.param">{{ content.storeData.name }}</router-link>
                    </div>
                </div>

                <div class="rating">
                    <button class="btn-review"
                        @click="openReviewModal()"
                        v-if="helper.isCompletedOrder(content.statusCode)
                            && !content.myRating.isCompleteReview">
                        ให้คะแนน
                    </button>
                    <button class="btn-review"
                        @click="openReviewModal()"
                        v-if="helper.isCompletedOrder(content.statusCode)
                            && content.myRating.isCompleteReview
                            && !content.myRating.isSubmitRewardAddress">
                        รับของขวัญ
                    </button>
                    <button class="btn-review done"
                        @click="openReviewModal()"
                        v-if="helper.isCompletedOrder(content.statusCode)
                            && content.myRating.isCompleteReview 
                            && content.myRating.isSubmitRewardAddress">
                        รีวิวของฉัน
                    </button>
                </div>
            </div>
        </div>

        <div class="panel-expanable">
            <button class="btn-expand" @click="toggleDetail()">
                ข้อมูลการสั่งซื้ออื่นๆ <b-icon icon="chevron-down" v-if="isShowDetail"></b-icon><b-icon icon="chevron-up" v-if="!isShowDetail"></b-icon>
            </button>

            <div class="detail" v-if="isShowDetail">
                <div class="item">
                    <label>หมายเลขคำสั่งซื้อ</label>
                    <span>{{ content.orderNumber }}</span>
                </div>
                <template v-if="content.orderType === constants.ORDER_TYPE.SERVICE">
                    <div class="item">
                        <label>ข้อมูลผู้ซื้อ</label>
                        <span>{{ content.customer.firstName }} {{ content.customer.lastName }}</span>
                    </div>
                    <div class="item">
                        <label>เบอร์โทร</label>
                        <span>{{ content.customer.tel }}</span>
                    </div>
                </template>
                <template v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
                    <div class="item">
                        <label>ชื่อผู้่รับ</label>
                        <span>{{ content.shippingData.receiverName }}</span>
                    </div>
                    <div class="item">
                        <label>เบอร์โทร</label>
                        <span>{{ content.shippingData.receiverTel }}</span>
                    </div>
                    <div class="item" v-if="content.orderType === constants.ORDER_TYPE.DELIVERY">
                        <label>ที่อยู่จัดส่ง</label>
                        <span>{{ content.shippingData.receiverAddress }}</span>
                    </div>
                </template>
                
                <div class="item">
                    <label>ข้อมูลรถยนต์</label>
                    <span>{{ content.customer.licenseNumber }} {{ content.customer.licenseProvince }} {{ content.customer.carBrand }} {{ content.customer.carModel }}</span>
                </div>
                <div class="item">
                    <label>ข้อมูลร้านค้า</label>
                    <span>
                        {{ content.storeData.name }}
                        <br />
                        โทร. {{ content.storeData.tel }}
                        <br />
                        วันเวลาทำการ: <span v-html="content.storeData.openTime"></span>
                    </span>
                </div>
            </div>
        </div>

        <modal-rating
            @close="closeReviewModal()"
            :reward="reward"
            :content="content"
            :isDisplay="isOpenReview">
        </modal-rating>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import Constants from '@/variables/constants';
import ModalRating from '@/components/user/ModalRating.vue';

export default {
    components: { ModalRating },
    data() {
        return {
            helper: Helper,
            routerPath: RouterPath,
            constants: Constants,
            isShowDetail: Helper.isWaitingOrder(this.content.statusCode),
            isOpenReview: false
        };
    },
    props: {
        reward: Object,
        content: Object,
        initShowDetail: Boolean
    },
    mounted() {
        this.isShowDetail = this.initShowDetail;
    },
    methods: {
        toggleDetail() {
            this.isShowDetail = !this.isShowDetail
        },
        displayPrice(price) {
            price = price ? price.toString().replace(',', '') : '';

            if (price && !isNaN(price)) {
                return Helper.getCommaNumber(parseFloat(price).toFixed(2));
            }

            return price;
        },
        checkZeroPrice(price) {
            price = price ? price.toString().replace(',', '') : '';

            if (price && price !== '0' && !isNaN(price)) {
                return true;
            }

            return false;
        },
        getStatusColor() {
            if (this.content.statusCode === Constants.ORDER_STATUS.COMPLETED_SERVICE
                || this.content.statusCode === Constants.ORDER_STATUS.COMPLETED_DELIVERY) {
                return 'text-success';
            }

            return 'text-danger';
        },
        openReviewModal() {
            this.isOpenReview = true;
        },
        closeReviewModal() {
            this.isOpenReview = false;

            this.$emit('reload');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.order {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;

    .info {
        font-size: 13px;

        @media only screen and (max-width: $screenExtraSmall) {
            max-width: 420px;
            margin: auto;
            font-size: 12px;
        }

        .item {
            display: flex;
            
            label {
                margin: 0;
                width: 130px;

                @media only screen and (max-width: $screenMedium) {
                    width: 120px;
                }
            }

            span {
                color: #555;
                flex: 1;
            }

            a {
                color: #555;
                text-decoration: underline;
                flex: 1;
            }
        }
    }

    .main {
        display: flex;
        align-items: flex-start;
        margin-top: 15px;

        @media only screen and (max-width: $screenExtraSmall) {
            display: block;
        }

        .info {
            padding: 0 15px;

            @media only screen and (max-width: $screenExtraSmall) {
                padding: 0;
            }
        }
        
        .picture {
            display: block;
            margin: 0 auto 20px;
            width: 150px;

            @media only screen and (max-width: $screenMedium) {
                width: 110px;
            }

            @media only screen and (max-width: $screenExtraSmall) {
                width: 120px;
            }

            img {
                border: 1px solid #555;
                display: block;
                width: 100%;
            }
        }

        .right {
            display: flex;
            flex: 1;
            align-items: flex-start;
            justify-content: space-between;

            @media only screen and (max-width: $screenLarge) {
                display: block;
            }

            .line {
                border-bottom: 1px dashed #ccc;
                margin: 10px 0;
            }

            .product {
                & + .product {
                    border-top: 1px dashed #ccc;
                    padding-top: 10px;
                    margin-top: 10px;
                }

                h4 {
                    margin: 0 0 7px;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 18px;

                    @media only screen and (max-width: $screenExtraSmall) {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }

            .price-data {
                .addon {
                    border-bottom: 1px dashed #ccc;
                    padding-bottom: 10px;
                    margin-bottom: 10px;

                    &:empty {
                        display: none;
                    }
                }

                .item {
                    font-size: 13px;
                    margin: 2px 0;
                    
                    @media only screen and (max-width: $screenMedium) {
                        font-size: 12px;
                    }

                    &.total {
                        font-size: 16px;
                        border-bottom: 1px dashed #ccc;
                        padding-bottom: 10px;
                        margin-bottom: 10px; 

                        @media only screen and (max-width: $screenMedium) {
                            font-size: 14px;
                        }

                        label, span {
                            font-weight: bold;

                            &.price:before {
                                font-weight: normal;
                            }
                        }
                    }

                    label {
                        margin: 0;
                        width: 130px;

                        @media only screen and (max-width: $screenMedium) {
                            width: 120px;
                        }
                    }

                    span {
                        color: #000;

                        &.price {
                            color: #cc0000;

                            &:before {
                                content: "฿";
                                color: #333;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }

            .rating {
                @media only screen and (max-width: $screenLarge) {
                    margin: 15px 10px 10px;
                }

                @media only screen and (max-width: $screenExtraSmall) {
                    margin: 15px 0 10px;
                }

                .btn-review {
                    background: #ffcc00;
                    border: none;
                    padding: 10px 22px;
                    width: 180px;
                    @include borderRadius(100px);

                    @media only screen and (max-width: $screenLarge) {
                        width: 100%;
                        padding: 7px 16px;
                    }

                    &.done {
                        background: #4385d1;
                        color: #fff;
                    }

                    span {
                        font-size: 16px;
                        font-family: 'Open Sans', 'Kanit';

                        @media only screen and (max-width: $screenLarge) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .panel-expanable {
        padding-left: 165px;

        @media only screen and (max-width: $screenMedium) {
            padding-left: 125px;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            padding-left: 0;
            max-width: 420px;
            margin: auto;
        }

        .btn-expand {
            margin-top: 10px;
            font-size: 13px;
            color: #555;
            padding: 3px 10px;
            border: 1px dashed #666;
            background: none;

            @media only screen and (max-width: $screenExtraSmall) {
                font-size: 13px;
                text-align: center;
            }
        }

        .detail {
            margin-top: 10px;

            .item {
                display: flex;
                align-items: flex-start;
                font-size: 13px;

                @media only screen and (max-width: $screenMedium) {
                    font-size: 12px;
                }

                label {
                    width: 130px;

                    @media only screen and (max-width: $screenMedium) {
                        width: 120px;
                    }
                }

                span {
                    flex: 1;
                    color: #777;
                }
            }
        }
    }
}
</style>